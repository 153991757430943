import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

const app = initializeApp({
  apiKey: "AIzaSyBRin61aINMxtcFCqxdmHDWB-mBLQGpgyo",
  appId: "1:216760873083:web:f9a726018ae15eb27f72de",
  measurementId: "G-37QK73T1CK",
  projectId: "cando-consulting",
});

const analytics = getAnalytics(app);
logEvent(analytics, 'notification_received');
